$span-padding: 3px;
$span-margin: 0.3;

@mixin span($numero, $margin) {
  width: (100 / 12) * $numero - ($margin * 2) * 1%;
  margin: $margin * 1%;
}


*, *:before, *:after { -moz-box-sizing: border-box;  box-sizing: border-box;  webkit-box-sizing: border-box; }

*, ul, ol { margin: 0px; padding: 0px; }
.clear { clear: both; height: 0px; }
.content { margin: 0 auto; position: relative; overflow: hidden; }
.row  { overflow: hidden; }

.content { width: 1200px; padding: 10px }

.slicknav_menu { display: none; }

.span1   { @include span(1, $span-margin) }
.span2   { @include span(2, $span-margin) }
.span3   { @include span(3, $span-margin) }
.span4   { @include span(4, $span-margin) }
.span5   { @include span(5, $span-margin) }
.span6   { @include span(6, $span-margin) }
.span7   { @include span(7, $span-margin) }
.span8   { @include span(8, $span-margin) }
.span9   { @include span(9, $span-margin) }
.span10  { @include span(10, $span-margin) }
.span11  { @include span(11, $span-margin) }
.span12  { @include span(12, $span-margin) }


.span1-nomargin   { @include span(1, 0) }
.span2-nomargin   { @include span(2, 0) }
.span3-nomargin   { @include span(3, 0) }
.span4-nomargin   { @include span(4, 0) }
.span5-nomargin   { @include span(5, 0) }
.span6-nomargin   { @include span(6, 0) }
.span7-nomargin   { @include span(7, 0) }
.span8-nomargin   { @include span(8, 0) }
.span9-nomargin   { @include span(9, 0) }
.span10-nomargin  { @include span(10, 0) }
.span11-nomargin  { @include span(11, 0) }
.span12-nomargin  { @include span(12, 0) }

.span1, .span2, .span3, .span4, .span5, .span6, .span7, .span8, .span9, .span10, .span11, .span12 { float: left; min-height:1px; padding: $span-padding; }
.span1-nomargin, .span2-nomargin, .span3-nomargin, .span4-nomargin, .span5-nomargin, .span6-nomargin, .span7-nomargin, .span8-nomargin, .span9-nomargin, .span10-nomargin, .span11-nomargin, .span12-nomargin { float: left; min-height:1px; padding: $span-padding;  }

@media screen and (max-width: 1100px){
    .content { width: 100%; } 
}

@media screen and (max-width: 768px){
    .content { width: 100%; }
    .span1, .span2, .span3, .span4, .span5, .span6, .span7, .span8, .span9, .span10, .span11, .span12 { width: 100%; margin: 0px; }
    .span1-nomargin, .span2-nomargin, .span3-nomargin, .span4-nomargin, .span5-nomargin, .span6-nomargin, .span7-nomargin, .span8-nomargin, .span9-nomargin, .span10-nomargin, .span11-nomargin, .span12-nomargin { width: 100%; margin: 0px; }

	.retina1  { @include span(1, $span-margin) }
	.retina2  { @include span(2, $span-margin) }
	.retina3  { @include span(3, $span-margin) }
	.retina4  { @include span(4, $span-margin) }
	.retina5  { @include span(5, $span-margin) }
	.retina6  { @include span(6, $span-margin) }
	.retina7  { @include span(7, $span-margin) }
	.retina8 { @include span(8, $span-margin) }
	.retina9  { @include span(9, $span-margin) }
	.retina10  { @include span(10, $span-margin) }
	.retina11  { @include span(11, $span-margin) }
	.retina12  { @include span(12, $span-margin) }

	.retina1-nomargin  { @include span(1, 0) }
	.retina2-nomargin  { @include span(2, 0) }
	.retina3-nomargin  { @include span(3, 0) }
	.retina4-nomargin  { @include span(4, 0) }
	.retina5-nomargin  { @include span(5, 0) }
	.retina6-nomargin  { @include span(6, 0) }
	.retina7-nomargin  { @include span(7, 0) }
	.retina8-nomargin { @include span(8, 0) }
	.retina9-nomargin  { @include span(9, 0) }
	.retina10-nomargin  { @include span(10, 0) }
	.retina11-nomargin  { @include span(11, 0) }
	.retina12-nomargin  { @include span(12, 0) }

}

@media screen and (max-width: 640px){   
    .content { width: 100%; }
    .span1, .span2, .span3, .span4, .span5, .span6, .span7, .span8, .span9, .span10, .span11, .span12 { width: 100%; padding: 5px; }
	.span1-nomargin, .span2-nomargin, .span3-nomargin, .span4-nomargin, .span5-nomargin, .span6-nomargin, .span7-nomargin, .span8-nomargin, .span9-nomargin, .span10-nomargin, .span11-nomargin, .span12-nomargin { float: left; min-height:1px; padding: 5px;  }

	.smart1  { @include span(1, $span-margin) }
	.smart2  { @include span(2, $span-margin) }
	.smart3  { @include span(3, $span-margin) }
	.smart4  { @include span(4, $span-margin) }
	.smart5  { @include span(5, $span-margin) }
	.smart6  { @include span(6, $span-margin) }
	.smart7  { @include span(7, $span-margin) }
	.smart8  { @include span(8, $span-margin) }
	.smart9  { @include span(9, $span-margin) }
	.smart10  { @include span(10, $span-margin) }
	.smart11  { @include span(11, $span-margin) }
	.smart12  { @include span(12, $span-margin) }

    .js .nav, #menu { display:none; } 
    .js .slicknav_menu { display:block; }

	.smart1-nomargin  { @include span(1, 0) }
	.smart2-nomargin  { @include span(2, 0) }
	.smart3-nomargin  { @include span(3, 0) }
	.smart4-nomargin  { @include span(4, 0) }
	.smart5-nomargin  { @include span(5, 0) }
	.smart6-nomargin  { @include span(6, 0) }
	.smart7-nomargin  { @include span(7, 0) }
	.smart8-nomargin  { @include span(8, 0) }
	.smart9-nomargin  { @include span(9, 0) }
	.smart10-nomargin  { @include span(10, 0) }
	.smart11-nomargin  { @include span(11, 0) }
	.smart12-nomargin  { @include span(12, 0) }

}
