@import "reset";
@import "base";
@import "varie";

body {
  background: $bianco;
  font-family: $fonts;
  color: #a4a4a4;
}

// TITOLI

h1 {
  &.titolo {
    font-weight: 300;
    b {
      font-weight: 700;
    }
    span {
      padding: 0px 10px;
    }
  }
  &.striscia {
    padding: 2px 5px;
    font-size: 13px;
    text-transform: uppercase;
    background: $grigiochiaro;
    padding-left: 70px;
    position: relative;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
    i {
      padding: 15px;
      background: $bianco;
      color: $grigiochiaro;
      border-radius: 50%;
      box-shadow: 0px 0px 6px rgba(20, 20, 20, 0.5);
      position: absolute;
      left: -5px;
      top: -12px;
      font-size: 25px;
    }
  }
}

.str {
  position: relative;
  .boxInfo {
    position: absolute;
    right: 5px;
    top: 2px;
    text-align: right;
    input,
    select {
      width: 150px;
      padding: 3px 5px;
    }
    label {
      display: none;
    }
    p {
      padding: 0px;
    }
  }
}

p b i,
p strong i,
p i b,
p i strong {
  color: #e41f29;
}

.aziendaFoto {
  @extend .shadow;
}

.pulsanti,
.pulsante {
  margin: 10px 0px;
  a {
    @include gradient(darken($blu, 10), $blu);
    font-size: 14px;
    color: $bianco;
    padding: 5px 10px;
    text-transform: uppercase;
    font-weight: normal;
    &:hover {
      @include gradient(darken($blu, 50), darken($blu, 10));
    }
  }
  &.pulsantebig {
    margin: 15px 0px;
    a {
      text-align: center;
      display: block;
    }
  }
}

.topmenu .content {
  overflow: inherit;
}

.share {
  padding: 10px 0px 20px 0px;
}

.bannersTop .bannerHeader {
  text-align: center;
  img {
    width: 200px;
    vertical-align: middle;
  }
}

.topmenu .content:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
}

.topmenu .menutopright .submenuCollection {
  display: none;
  position: absolute;
  z-index: 50;
  background: $bianco;
  li {
    display: block;
  }
}

.topmenu .menutopright li:hover ul {
  display: block;
}

// SOCIAL & LANG

.social {
  a {
    margin-right: 3px;
    font-size: 23px;
  }
  a.icon-fb {
    color: #3b5998;
  }
  a.icon-pi {
    color: #c91e26;
  }
  a.icon-yt {
    color: #eb3323;
  }
  a:hover {
    color: $grigio;
  }
}

#language {
  position: absolute;
  top: 0px;
  right: 0px;
  li {
    display: inline-block;
    img {
      height: 10px;
    }
  }
}

.freeShipping {
  position: absolute;
  z-index: 5;
  width: 55px;
  height: 55px;
  top: 25px;
  right: 5px;
}

h4.freeShippingText {
  color: #ed1a3b;
}

// PAGINAZIONE

.pagination {
  overflow: hidden;
  padding: 20px 0px;
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  background: $bianco;
  li {
    display: inline-block;
    font-family: $serif;
    font-size: 13px;
    margin-left: 4px;
    margin-right: 4px;
    span {
      padding: 2px;
      color: $grigio;
      border-radius: 50%;
      width: 16px;
      display: block;
    }
    a {
      padding: 2px;
      width: 16px;
      text-align: center;
      border-radius: 50%;
      color: $nero;
      font-weight: normal;
      display: block;
    }
    a:hover,
    &.active span {
      color: $grigio;
      box-shadow: $shadow;
      font-weight: normal;
    }
  }
}

.content.nohidden {
  overflow: inherit;
}

.separato {
  padding-top: 10px;
  background: url(../../img/bkg-separator.png) top center no-repeat;
}

// BANNER

.banner,
.sx-ricerca {
  margin: 10px 0px;
  h4 {
    text-align: center;
    color: $grigio;
    padding: 6px 1px;
    margin-bottom: 3px;
    text-align: center;
    text-transform: uppercase;
    background: $grigiochiaro;
    display: block;
    font-size: 11px;
    font-weight: bold;
  }
  a {
    box-shadow: $shadow;
    padding: 2px;
    display: block;
    img {
      max-width: 100%;
    }
    .puls {
      display: block;
      margin-bottom: 5px;
      padding: 4px;
      text-align: center;
      @include gradient(darken($blu, 10), $blu);
      color: $bianco;
      font-size: 12px;
    }
    &:hover {
      .puls {
        @include gradient($grigio, darken($grigio, 20));
        color: $bianco;
      }
    }
  }
}

// SLIDER HOME

.slider-home {
  position: relative;
  z-index: 2;
  li {
    width: 100%;
    height: 300px;
  }
  .img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 300px;
    display: block;
  }
  .content {
    z-index: 3;
    position: relative;
  }
  .txt {
    padding: 10px 0px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 3;
    position: relative;
    margin-top: 5px;
    box-shadow: 0px 0px 4px rgba(20, 20, 20, 0.7);
    p {
      color: $nero;
      width: 400px;
    }
  }
  h2,
  h3 {
    font-size: 16px;
    display: block;
    margin: 15px 0px;
    span {
      padding: 5px;
      background: $blu;
      color: $bianco;
    }
  }
  h3 {
    span {
      background: $grigiochiaro;
      color: $nero;
    }
  }
  .owl-dots {
    position: absolute;
    bottom: 10px;
    right: 10px;
    .owl-dot span {
      margin: 5px;
      width: 10px;
      height: 10px;
      background: none;
      border: 2px solid $grigio;
    }
    .owl-dot.active span {
      background: $grigio;
    }
  }
  .pulsante {
    margin-top: 10px;
    font-size: 13px;
    a {
      @include gradient($oro, $oroscuro);
      color: $nero;
      text-transform: uppercase;
      font-family: $serif;
      padding: 5px 7px;
      box-shadow: 0px 0px 4px rgba(20, 20, 20, 0.7);
      font-weight: normal;
      &:hover {
        @include gradient(darken($blu, 20), $blu);
        color: $bianco;
      }
    }
  }
  .owl-controls {
    width: 1000px;
    margin: 0px auto;
    position: relative;
  }
}

.ultimiProdottiSlider-area {
  padding: 10px 20px;
  margin: 0px 10px;
  box-shadow: 0px 0px 8px rgba($grigio, 0.5) inset;
  .prodotto {
    box-shadow: none;
  }
}

// HEADER E FOOTER

header {
  position: relative;
  z-index: 15;
  .content {
    padding: 2px 0px;
  }
  .logo {
    padding-top: 0px;
  }
  .striscia {
    background: $grigiochiaro;
    padding: 5px;
    text-align: center;
    .content {
      position: relative;
      padding: 0px;
      p {
        font-size: 10px;
        color: $grigio;
        text-align: center;
        padding: 0px;
        padding-right: 50px;
        margin: 0px;
      }
    }
  }
  .boxTop {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    padding-top: 20px;
    color: $nero;
    &.ufficiale {
      width: 180px;
      p {
        font-size: 13px;
        text-align: left;
        float: left;
        widows: 70px;
      }
      a img {
        padding-top: 5px;
      }
    }
    p {
      font-size: 11px;
      color: $nero;
    }
  }
  .area-riservata {
    a {
      padding: 5px;
      margin-left: 5px;
      text-align: right;
      border: 1px solid $grigiochiaro;
      font-family: $serif;
      color: $grigio;
      &.registrati {
        color: $bianco;
        background: $blu;
      }
      &:hover,
      &.registrati:hover {
        color: $grigio;
        background: $grigiochiaro;
        color: $nero;
      }
    }
  }
  .ricerca {
    padding-bottom: 5px;
    input {
      width: 150px;
      &:focus {
        width: 200px;
      }
    }
    button {
      padding: 8px;
    }
    .pulsante a {
      margin-left: 10px;
      padding: 8px;
      color: $grigio;
      background: $grigiochiaro;
      font-size: 13px;
      font-family: $serif;
      &:hover {
        background: $blu;
        color: $bianco;
      }
    }
  }
}

.sliderHome {
  background: $blu;
  padding-top: 20px;
  padding-bottom: 20px;
  * {
    color: $bianco;
  }
  h2 {
    font-size: 40px;
    font-weight: 900;
    line-height: 45px;
  }
}

.sliderPage {
  background: $blu;
  height: 20px;
}

footer {
  margin-top: 10px;
  .footerMenu {
    background: $bianco;
    .content {
      padding: 0px;
      overflow: -webkit-paged-x;
      .boxFooter {
        padding: 18px;
        overflow: hidden;
        &.first {
          position: relative;
          z-index: 100;
        }
        h2 {
          padding: 10px 0;
          text-transform: uppercase;
          font-size: 30px;
          font-weight: 900;
          text-align: center;
        }
        li a {
          font-size: 12px;
          color: darken($grigiochiaro, 10);
          font-weight: normal;
        }
        li.active a,
        li a:hover {
          color: $grigio;
          font-weight: bold;
        }
        p,
        li {
          font-size: 13px;
        }
      }
    }
  }
  .footer {
    background: $blu;
    border-top: 20px solid $arancione;
    * {
      color: $bianco;
    }
    ul {
      padding: 0px;
      margin: 0px;
      li {
        display: inline-block;
      }
    }
  }
  .copy {
    border-top: 1px solid lighten($blu, 5);
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    background: $blu;
    .content {
      position: relative;
      padding: 0px;
      p {
        font-size: 12px;
        color: $bianco;
        text-align: left;
        padding: 0px;
        margin: 0px;
      }
    }
    a {
      color: $bianco;
    }
  }
  #footermenu {
    text-align: right;
    p,
    ul,
    li {
      font-size: 13px;
    }
  }
  #submenu {
    li {
      padding-top: 10px;
      margin-left: 20px;
      &.active a,
      a:hover {
        color: lighten($blu, 30);
      }
    }
  }
}

// CARRELLO

.crt-sml {
  i {
    padding-right: 10px;
    font-size: 20px;
    vertical-align: middle;
  }
  p {
    font-family: $serif;
    font-size: 12px;
    color: $bianco;
    padding: 0px;
  }
  a {
    padding: 5px;
    background: $bianco;
    color: $nero;
    vertical-align: middle;
    margin-left: 10px;
    &:hover {
      color: $grigio;
    }
  }
  .crt-qnt,
  .crt-euro {
    font-size: 12px;
  }
  .fullCarrello a {
    background: $arancione;
    color: $bianco;
    &:hover {
      background: #444444;
    }
  }
}

header .boxTop.wishlist {
  i {
    padding-right: 10px;
  }
  p {
    font-family: $serif;
    font-size: 12px;
    color: $bianco;
    padding: 0px;
  }
  a {
    padding: 5px;
    background: $bianco;
    color: $nero;
    vertical-align: middle;
    margin-left: 10px;
    font-weight: normal;
    &:hover {
      color: $grigio;
    }
  }
}

// SX

.sx {
  margin-top: 5px;
  ul {
    font-family: $serif;
    background: $bianco;
    li {
      padding: 0px;
      a {
        color: $nero;
        font-size: 14px;
        font-weight: normal;
        padding: 7px 6px;
        background: $bianco;
        display: block;
        border-bottom: 1px solid $grigiochiaro;
        small {
          color: lighten($grigio, 25);
          font-size: 11px;
        }
      }
      &.active a,
      a:hover {
        color: $bianco;
        background: $arancione;
        small {
          color: lighten($arancione, 25);
          font-size: 11px;
        }
      }
    }
    &.bar-sx {
      li {
        a {
          background: $blu;
          color: $bianco;
        }
        &.active a,
        a:hover {
          color: $bianco;
          background: $nero;
        }
      }
    }
  }
}

.listaCat {
  padding: 10px;
  ul {
    padding: 0px;
    margin: 0px;
    text-align: center;
    li {
      display: inline-block;
      font-size: 13px;
      a {
        padding: 4px 10px;
        background: $arancione;
        color: $bianco;
        small {
          font-size: 11px;
          color: lighten($arancione, 40);
        }
      }
      &.active a,
      a:hover {
        background: $blu;
        small {
          color: lighten($blu, 40);
        }
      }
    }
  }
}

.diconoQuote {
  font-size: 40px;
  color: $grigiochiaro;
  padding-top: 10px;
  text-align: center;
}

.sx-ricerca {
  .pulsante a {
    padding: 5px;
    text-align: center;
    text-transform: none;
  }
  h4 {
    background: $blu;
    color: $bianco;
  }
}

// MAIN

#main {
  background: $grigiochiaro;
  .contenuto {
    padding: 0px 10px;
    p {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

// PRODOTTI

.prodotto {
  padding: 10px;
  background: $bianco;
  height: 410px;
  position: relative;
  p.img {
    text-align: center;
    padding: 0px;
    margin: 0px;
    img {
      max-width: 90%;
    }
  }
  .prezzo {
    text-align: center;
    font-family: $serif;
    font-weight: bold;
    color: $grigio;
    font-size: 16px;
    display: block;
    small {
      font-size: 14px;
      color: darken($grigiochiaro, 30);
      text-decoration: line-through;
    }
  }
  .pulsante {
    margin-top: 10px;
    a {
      @include gradient($oro, $oroscuro);
      display: block;
      text-align: center;
      font-family: $serif;
      font-weight: bold;
      &:hover {
        @include gradient(darken($oroscuro, 10), darken($oroscuro, 50));
        color: $bianco;
      }
    }
  }
  p.category {
    padding: 4px 0px;
    text-align: center;
    a {
      font-weight: normal;
    }
  }
  h3 {
    text-align: center;
    padding-top: 4px;
    font-size: 14px;
    text-transform: uppercase;
    height: 55px;
    line-height: 15px;
    a {
      color: $nero;
      font-weight: 600;
      &:hover {
        color: $grigio;
      }
    }
  }
}
.listaprodotti-content {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
  .loader {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: none;
    z-index: 5;
    min-height: 300px;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    img {
      margin-top: 80px;
    }
  }
}

.ricercaBox {
  font-size: 12px;
  color: $grigio;
  font-family: $serif;
  line-height: 28px;
  margin-top: -10px;
  border-bottom: 1px solid $grigiochiaro;
  border-top: 1px solid $grigiochiaro;
  margin-bottom: 10px;
  select {
    padding: 3px 0px;
    font-size: 12px;
    font-family: $serif;
  }
  .range {
    padding-left: 10px;
    margin-top: -8px;
    font-size: 13px;
  }
  .ui-widget-header {
    background: $grigio;
  }
  .ui-widget-content {
    border: 1px solid $grigiochiaro;
  }
  .ui-slider .ui-slider-handle {
    height: 28px;
  }
  .ui-slider-horizontal {
    height: 22px;
    margin-top: -5px;
  }
}

.prodotto-dettaglio {
  .varArea {
    display: block;
    overflow: hidden;
  }
  .box-prodotto {
    margin: 10px;
    padding: 10px;
    background: $bianco;
    position: relative;
    h4 {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid $grigiochiaro;
    }
    h2 {
      text-transform: uppercase;
      font-size: 16px;
    }
    .social {
      position: absolute;
      right: 10px;
      top: 5px;
      a {
        color: $grigio;
      }
      a.icon-fb:hover {
        color: #3b5998;
      }
      a.icon-pi:hover {
        color: #c91e26;
      }
    }
  }
  .prezzo {
    font-size: 66px;
    padding: 10px 0px;
    font-family: "Open Sans Condensed", "Open Sans", Arial;
    color: $grigio;
    small {
      font-size: 25px;
      i {
        text-decoration: line-through;
      }
    }
  }
  .aggiungibutton {
    text-align: center;
    margin: 10px 0;
    a {
      background: $blu url(../../img/acq.png) center left no-repeat;
      padding: 10px;
      display: block;
      padding: 8px;
      font-size: 20px;
      text-transform: uppercase;
      color: $bianco;
      font-family: $serif;
      &:hover {
        background: $grigio url(../../img/acq.png) center left no-repeat;
      }
    }
  }
  .boxPadding {
    padding: 15px 20px;
    overflow: hidden;
  }
  .descrizione {
    p {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .aggiungiquantita {
    display: inline-block;
  }
  .aggiungiquantita {
    font-family: $serif;
    font-style: italic;
    padding: 10px 0px;
    input {
      background: $bianco;
      background: $bianco;
      width: 42px;
      border: 0px;
      text-align: center;
      color: $grigio;
      font-size: 20px;
      font-family: $serif;
    }
    a.qnt {
      background: $grigio;
      font-weight: normal;
      color: $bianco;
      padding: 11px 6px 8px 6px;
      display: inline-block;
      width: 30px;
      text-align: center;
      &:hover {
        background: $blu;
        color: $bianco;
      }
    }
    a.qnt-rm {
      margin-left: 10px;
    }
  }

  .area-Prodotto-Acqusita {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    .loader {
      width: 100%;
      height: 100%;
      background: rgba($bianco, 0.7);
      text-align: center;
      position: absolute;
      display: none;
      img {
        padding-top: 50px;
      }
    }
  }
}

.box-acquista {
  .prezzo {
  }
}

// MUSEI & MOSTRE

.museoList {
  overflow: hidden;
  padding: 10px 0px;
  border-bottom: 1px dashed $grigiochiaro;
  p {
    font-size: 14px;
    line-height: 18px;
    padding-right: 10px;
  }
  h3,
  h4 {
    font-size: 16px;
    line-height: 20px;
  }
  h4 {
    color: lighten($grigio, 20);
    font-style: italic;
  }
  img {
    padding-top: 20px;
  }
  .pulsanti {
    padding: 10px 0px;
    a {
      margin-right: 20px;
    }
  }
}

.boxMuseo {
  margin: 30px 0px;
  padding: 10px;
  box-shadow: $shadow;
  h3,
  h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .pulsanti {
    margin-top: 10px;
    a {
      color: $grigio;
      background: none;
      border-bottom: 1px solid $grigio;
      padding: 10px 0px;
      &:hover {
        color: $grigiochiaro;
      }
    }
  }
}

.correlati {
  padding: 30px 0px 15px 0px;
  h2 {
    font-size: 20px;
    font-weight: bold;
    padding: 5px;
  }
}

.prodotto-dettaglio .correlati .prezzo {
  text-align: center;
  font-family: $serif;
  font-weight: bold;
  color: $grigio;
  font-size: 16px;
  display: block;
  small {
    font-size: 14px;
    color: darken($grigiochiaro, 30);
    text-decoration: line-through;
  }
}

// BLOG MOSTRE MUSEI

.galleryPost {
  padding: 10px 0px;
  margin-top: 10px;
  border-top: 1px dashed $grigiochiaro;
  img {
    margin: 5px;
  }
}

.postList,
.postDetail {
  padding: 10px;
  margin-bottom: 10px;
  background: $bianco;
}

.postList,
.postDetail {
  p.data {
    font-family: $serif;
    font-size: 15px;
    color: $grigio;
  }
}

.ricerca-avanzata {
  button {
    width: 100%;
  }
}

#main {
  .infoBadgeArea {
    position: relative;
    .infoBadge {
      position: absolute;
      bottom: 0px;
      left: 10px;
      text-align: center;
      width: 95px;
      height: 95px;
      padding: 10px 2px;
      background: url(../../img/baloon.png) top center no-repeat;
      margin-bottom: -50px;
      p {
        font-family: $serif;
        color: $grigio;
        font-size: 12px;
        line-height: 17px;
        padding-top: 15px;
        font-weight: bold;
        b {
          font-size: 12px;
          color: $bianco;
        }
      }
    }
  }
}

// CHECKOUT

.cart,
.lastCarrello {
  table {
    width: 100%;
    font-family: $serif;
    margin-bottom: 10px;
    td,
    th {
      padding: 10px;
      border-right: 2px dashed darken($grigiochiaro, 10);
      vertical-align: middle;
    }
    td:last-child,
    th:last-child {
      border-right: 0px;
    }
    th {
      text-align: left;
      font-weight: bold;
      background: $grigiochiaro;
      color: $nero;
    }
    td.crt-prezzo,
    td.crt-totale,
    th.crt-prezzo,
    th.crt-totale,
    td.td-numero {
      text-align: center;
      font-weight: bold;
    }
    tr.rw-totale td {
      background: $grigio;
      font-size: 18px;
      color: $bianco;
      text-transform: uppercase;
    }
    tr.rw-totale-spedizione td {
      background: lighten($grigio, 20);
      font-size: 16px;
      color: $bianco;
      font-weight: bold;
    }
    td.td-numero a {
      padding: 4px 5px;
      margin: 0px 5px;
      color: $bianco;
      background: $blu;
      border-radius: 50%;
      &:hover {
        background: $grigio;
      }
    }
    td.td-numero {
      width: 180px;
    }
    td {
      p {
        b {
          font-size: 16px;
        }
      }
      a.bt-canc {
        color: #c91e26;
        font-size: 26px;
        &:hover {
          color: $grigio;
        }
      }
    }
  }
}

.lastCarrello {
  padding-bottom: 20px;
  padding-top: 20px;
  table {
    margin-top: 20px;
  }
}

.ordine-img {
  float: left;
  margin-right: 10px;
}

.checkout {
  .btn {
    text-transform: uppercase;
    font-size: 18px;
    font-family: $serif;
    margin-top: 20px;
  }
  small {
    font-size: 12px;
  }
}

p.spese_spedizione_small {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.areacart {
  position: relative;
  overflow: hidden;
  padding: 10px;
  background: $bianco;
  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba($bianco, 0.8);
    display: none;
    img {
      padding-top: 100px;
    }
  }
}

.pulsante.acquistafinale {
  a {
    width: 49%;
    margin: 10px 0px;
    display: inline-block;
    padding: 10px;
    text-align: center;
    &.continua {
      background: $grigiochiaro;
      color: $nero;
      &:hover {
        background: $blu;
        color: $bianco;
      }
    }
  }
}

.nascosto {
  display: none;
}
.metodi {
  img,
  input {
    vertical-align: middle;
  }
}

.privatoAzienda {
  padding: 10px 0px;
  input {
    margin-left: 10px;
    vertical-align: middle;
  }
}

.azienda {
  display: none;
}

.provinceEng,
.provinceIta {
  display: none;
}

.faq {
  padding: 10px 0px;
  border-bottom: 1px dashed $grigiochiaro;
  h3 {
    color: $grigio;
    small {
      color: lighten($blu, 30);
      font-style: italic;
    }
  }
  &:last-child {
    border-bottom: 0px;
  }
}

.opera-area {
  position: absolute;
  top: 60px;
  right: 0px;
  text-align: right;
  a.operapuls {
    font-size: 25px;
    font-weight: bold;
    padding: 3px 4px;
    border-radius: 50% 0 0 50%;
    color: $grigio;
    background: $grigiochiaro;
    box-shadow: $shadow;
    &:hover {
      background: $grigio;
      color: $bianco;
    }
  }
  .opera-area-nascosta {
    margin-top: 10px;
    padding: 10px;
    width: 300px;
    background: $bianco;
    box-shadow: $shadow;
    text-align: left;
    display: none;
  }
}

.logo-nascosto {
  display: none;
  img {
    width: 120px;
  }
}

.logo-normale {
  display: block;
  p {
    text-transform: uppercase;
    color: $blu;
    font-size: 14px;
    letter-spacing: 4px;
    font-weight: bold;
  }
}

.tutteOfferte {
  text-align: center;
  a {
    padding: 5px 8px;
    background: $blu;
    color: $bianco;
    &:hover {
      background: $arancione;
    }
  }
}

.slicknav_menu {
  background: #fff;
}
.slicknav_menu li {
  text-align: right;
}
.slicknav_menu li a {
  color: #444444;
}
.slicknav_menu li.active a:hover,
.slicknav_menu li a:hover {
  background: #dbdbdb;
  color: #444444;
}

.categories-sx {
  display: block;
}
.categories-sx-hidden {
  display: none;
}

.sliderScontiContentSmall {
  display: none;
}
.sliderScontiContent {
  display: block;
}

@media screen and (max-width: 768px) {
  .sxCat {
    display: none;
  }
  .categories-sx {
    display: none;
  }
  .categories-sx-hidden {
    display: block;
  }
  .slider-home {
    width: 100%;
  }
  .slider-home li,
  .slider-home .img {
    height: 200px;
  }
  .slider-home .img {
    width: 100%;
    height: 100%;
    background-size: 200%;
  }
  .slider-home {
    .owl-controls {
      width: 100%;
      margin: 0px auto;
      position: relative;
    }
  }
  .prodotto {
    height: 320px;
  }
}

@media screen and (max-width: 640px) {
  .logo-normale {
    display: none;
  }
  .logo-nascosto {
    display: block;
    padding: 5px;
    position: absolute;
    top: 5px;
    width: 40%;
    z-index: 10;
    img {
      width: 100%;
    }
  }
  .prodotto {
    height: 410px;
  }
  .sliderScontiContentSmall {
    display: block;
  }
  .sliderScontiContent {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .slider-home {
    .owl-controls {
      width: 100%;
      margin: 0px auto;
      position: relative;
    }
  }
  .prodotto {
    height: 410px;
  }
}

@media only screen and (max-width: 768px) {
  .prodotto {
    height: 320px;
  }

  .freeShipping {
    width: 45px;
    height: 45px;
    top: 45px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .prodotto {
    height: 410px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .prodotto {
    height: 320px;
  }

  .freeShipping {
    width: 45px;
    height: 45px;
    top: 45px;
  }
}

table.tabella-ordini-finali {
  width: 100%;
}
table.tabella-ordini-finali th {
  border-bottom: 2px solid #444444;
  font-weight: bold;
}
table.tabella-ordini-finali th,
table.tabella-ordini-finali td {
  padding: 4px;
  border-bottom: 1px solid #dbdbdb;
}
table.tabella-ordini-finali tr.tr-totale td {
  background: #f4f4f4;
  border-top: 2px solid #444444;
  border-bottom: 2px solid #444444;
  font-weight: bold;
}

.ordinelista {
  padding: 10px 0px;
  border-bottom: 1px dashed #444444;
  margin-bottom: 20px;
}

.nondisponibile-2,
.nondisponibile-0 {
  display: block;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.nondisponibile-2 {
  background: #dbdbdb;
  color: #444444;
}

.nondisponibile-0 {
  background: #444444;
  color: #fff;
}

.cookie {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: rgba(102, 102, 102, 0.9);
  padding: 20px;
  z-index: 1000;
}
.cookie p {
  font-size: 13px;
  color: #fff;
  text-align: center;
}
.cookie a {
  font-weight: bold;
  padding: 3px;
  background: #000;
  color: #fff;
}
.cookie a:hover {
  background: #fff;
  color: #000;
}

table.table-varianti {
  font-family: $serif;
  font-weight: normal;
  color: $nero;
  font-size: 13px;
  line-height: 17px;
  border-top: 1px solid #bbb;
  td {
    padding: 5px 0px;
    border-bottom: 1px solid #bbb;
  }
  .td-buy a {
    text-align: center;
    background: $blu;
    color: $bianco;
    padding: 8px 10px;
    display: block;
    &:hover {
      background: #000;
    }
  }
  .td-price {
    color: $blu;
    font-weight: bold;
  }
}
.owl-carousel .owl-item .prdMarchio img {
  width: auto;
  display: inline-block;
}

.fullCarrello {
}

.contenuto ul li {
  list-style: disc inside;
}
.contenuto ol li {
  list-style: decimal inside;
}


p.stockMessage {
  color: red;
  font-size: 15px;
  font-weight: bold;
}