$radius: 5px;
$textShadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
$shadow: 0px 0px 4px rgba(20, 20, 20, 0.5);
$fonts: "Open Sans", sans-serif;
$serif: "Open Sans", sans-serif;
$serif2: "Oswald", sans-serif;

$nero: #222327;
$grigio: #444444;
$grigiomedio: #939393;
$grigiochiaro: #e4e5e7;
$arancione: #ed1a3b;
$bianco: #fff;
$blu: #25247b;
$panna: #eae2d0;
$oro: #d1c8b4;
$oroscuro: #aea696;

.radius {
  border-radius: 5px;
}
.shadow {
  box-shadow: 0px 0px 4px rgba(20, 20, 20, 0.6);
}

@mixin gradient($from, $to) {
  background: $from;
  background: -moz-linear-gradient(top, $from 0%, $to 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $from),
    color-stop(100%, $to)
  );
  background: -webkit-linear-gradient(top, $from 0%, $to 100%);
  background: -o-linear-gradient(top, $from 0%, $to 100%);
  background: -ms-linear-gradient(top, $from 0%, $to 100%);
  background: linear-gradient(to bottom, $from 0%, $to 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=0 );
}

@mixin radial($from, $to) {
  background: $from; /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    $from 0%,
    $to 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, $from),
    color-stop(100%, $to)
  ); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    $from 0%,
    $to 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    $from 0%,
    $to 100%
  ); /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    $from 0%,
    $to 100%
  ); /* IE10+ */
  background: radial-gradient(ellipse at center, $from 0%, $to 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

/* VARIE */

.acenter {
  text-align: center;
}
.aright {
  text-align: right;
}
.aleft {
  text-align: left;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: $nero;
  &:hover {
    color: lighten($grigio, 50);
  }
}

img.img-100 {
  width: 100%;
}
img.img-max-100 {
  max-width: 100%;
}

/* FORM */

label {
  display: block;
  padding: 2px 0px;
  font-size: 12px;
  font-style: italic;
}

input,
select,
textarea {
  padding: 7px;
  width: 100%;
  border: 1px solid #ccc;
  font-family: $fonts;
  font-size: 13px;
}

input[type="checkbox"],
input[type="radio"] {
  width: auto;
  border: 1px solid #cccccc;
  margin-right: 5px;
  border: 1px solid #ccc;
  background: none;
  box-shadow: 0px 0px 0px rgba($bianco, 0);
}
textarea {
  height: 150px;
}
input:focus,
textarea:focus,
select:focus {
  border: 1px solid #aaa;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
}
input.inp-radio {
  width: auto;
  margin-right: 5px;
  background: none;
  box-shadow: 0px 0px 0px rgba($bianco, 0);
}

.form-horizontal .control-group:before,
.form-horizontal .control-group:after {
  content: "";
  display: table;
  line-height: 0;
}
.form-horizontal .control-group:after {
  clear: both;
}
.form-horizontal .control-group:before,
.form-horizontal .control-group:after {
  content: "";
  display: table;
  line-height: 0;
}

input.btn,
button {
  cursor: pointer;
  width: auto;
  padding: 8px 20px;
  background: $blu;
  color: $bianco;
  border: 0px;
  text-align: center;
  &:hover {
    background: $nero;
    color: $bianco;
  }
  &.btn-big {
    width: 100%;
  }
}

p.errore {
  padding: 3px;
  margin: 1px 2px;
  background: #cb605a;
  color: #fff;
}

/* MENU */

.topmenu {
  font-family: $serif;
  border-top: 1px solid $grigiochiaro;
  margin-top: 5px;
  position: relative;
  z-index: 5;
  .content,
  .span12 {
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .span1-nomargin,
  .span2-nomargin,
  .span3-nomargin,
  .span4-nomargin,
  .span5-nomargin,
  .span6-nomargin,
  .span7-nomargin,
  .span8-nomargin,
  .span9-nomargin,
  .span10-nomargin,
  .span11-nomargin,
  .span12-nomargin {
    padding: 0px;
  }
  ul {
    padding-left: 0px;
    margin-left: 0px;
  }
  #menu,
  .menutopright {
    font-size: 14px;
    padding: 0px;
    li {
      display: inline-block;
      margin-right: 5px;
    }
    a {
      color: $nero;
      font-weight: normal;
      display: block;
      padding: 10px 10px;
      text-transform: uppercase;
      font-weight: 600;
    }
    li:last-child a {
      background: none;
    }
    li a:hover,
    li.active a {
      background: $blu;
      color: $bianco;
    }
  }
  .menutoprightitems {
    form,
    ul {
      display: inline-block;
    }
    form {
      position: relative;
    }
    i {
      position: absolute;
      left: 10px;
      top: 8px;
      font-size: 18px;
      color: #ccc;
    }
    input {
      box-shadow: none;
      width: 150px;
      border: 0px;
      padding-left: 35px;
    }
    button {
      background: #fff;
    }
  }
}

.spedizioni {
  padding: 10px;
  background: $grigiochiaro;
  p {
    text-align: center;
    font-weight: 600;
  }
  &.bigger {
    padding: 10px;
    background: $grigiochiaro;
    p {
      text-align: center;
      font-weight: 600;
      font-size: 22px;
      color: $arancione;
    }
  }
}

// TIPOGRAFIA

h1,
h2,
h3,
h4 {
  font-family: $serif;
  font-weight: normal;
  color: $nero;
  b,
  strong {
    font-weight: 700;
  }
}

p,
ul,
li,
ol {
  font-weight: normal;
  color: $nero;
}

li {
  padding: 2px 0px;
}

h1 {
  font-size: 30px;
  line-height: 35px;
  padding: 15px 0px;
  font-weight: 900;
}
h2 {
  font-size: 25px;
  line-height: 28px;
  font-weight: 600;
}
h3 {
  font-size: 20px;
  line-height: 25px;
}
h4 {
  font-size: 15px;
  line-height: 18px;
}

h2.titolo, h1.titolo {
  font-size: 30px;
  line-height: 35px;
  padding: 15px 0px;
  font-weight: 900;
  text-align: center;
}

h3.evidenziato {
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  background: $grigiomedio;
  color: $nero;
  margin: 5px 0px;
}

p {
  font-size: 13px;
  line-height: 17px;
  color: $nero;
  padding: 5px 0px;
  font-family: $fonts;
  font-weight: normal;
  strong,
  b {
    font-weight: 700;
  }
  i {
    font-style: italic;
  }
}

i {
  font-style: italic;
}

.aleft p {
  text-align: left;
}
.acenter p {
  text-align: center;
}
.aright p {
  text-align: right;
}
